import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"

import { HeroStatic } from "../components/hero"
import ValueBox from "../components/valuebox_new"
import Schedule from "../components/schedule"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactFormTrial } from "../components/contactform"
import SideBlocks from "../components/side-blocks"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, H1, P } from "../components/styledcomponents"
import Button from "../components/button"

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const BJJ = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="Brazilian Jiu-Jitsu / BJJ in München - Team Laurien & Alex - kostenlose Probestunde vereinbaren"
        description="Vereinbare jetzt eine kostenlose Probestunde für BJJ in unserer Kampfsportschule in München!"
      />
      {/* <HeroStatic
        title="Kostenloses BJJ Probetraining vereinbaren"
        img={data.hero1}
        imgAlt="BJJ in München"
        color="#ffffff"
        buttoncolor="#f17915"
      /> */}
      <Image
        image={data.hero1.childImageSharp.gatsbyImageData}
        alt="Unsere BJJ Gruppe in München"
      />
      {/* <ValueBox
        values={[
          { id: 1, value: "Stressabbau" },
          { id: 2, value: "Körperliche Fitness & Mentale Fitness" },
          { id: 3, value: "Lösungsorientiertes Denken" },
          { id: 4, value: "Team Spirit" },
        ]}
      /> */}
      <div>
        <H1 noLine center>Jetzt ein kostenloses BJJ Probetraining vereinbaren:</H1>
        <ContactFormTrial page_source="bjj-Probetraining" />
      </div>
      <ContentAndPicture
        h2="Das ist Brazilian Jiu Jitsu / BJJ"
        description={<>
          <P>
            Brazilian Jiu Jitsu ist eine faszinierende Disziplin, die es ermöglicht, den Gegner durch geschickte Körpermechanik zu kontrollieren. Mit cleveren Strategien kann man sich sogar gegen stärkere und größere Kontrahenten durchsetzen.
            Obwohl BJJ ein Spiel der Stärke ist, liegt unser Fokus nicht auf dem Einsatz von Kraft sonderm auf der Fähigkeit Probleme zu lösen. Dabei fördern wir Spaß und körperliche Fitness.
          </P>
          <Button
            to={"#contact"}
          >        Kostenlose Probestunde sichern
          </Button>
        </>
        }
        imgLeft={data.hiptoss}
      />

      < SideBlocks >
        Unser Ziel ist, Menschen dabei zu helfen, erfolgreich zu sein, und nicht Gründe zu finden, warum sie es nicht können!
      </SideBlocks>

      <ContentAndPicture
        h2="Wie läuft ein BJJ Probetraining ab?"
        list={[
          "Zu deinem ersten Training komme bitte 15 Minuten früher, damit wir dich und deine Ziele besser kennenlernen und dir somit die beste Erfahrung anbieten können.",
          "Für deine Probestunde brauchst du nur bequeme Sportklamotten und etwas zu trinken.",
          "Warm Up: BJJ relevante Spiele mit spezifischen Aufgaben (task focus).",
          "Hauptteil: je nach Thema experimentierst du mit verschiedenen Konzepten des BJJs.",
          "Sparring / Rolling: Übungskämpfe. Dabei geht es darum, das Geübte mit Widerstand anzuwenden. Dabei ist uns immer wichtig, dass sich alle sicher fühlen, damit alle effektiv, mit Spaß und ohne Verletzungen rollen können.",
        ]}
        description2={<>
          <Button to={"#contact"}>
            Kostenlose Probestunde sichern
          </Button>
        </>}
        ordered
        imgRight={data.heelhook}
      />

      <Container backgroundColor="transparent" padding="1em 0" />
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "Generell gibt mir das Training eine Struktur für den Tag (gerade als Student ist das sehr hilfreich). Außerdem kann ich im Training alles um mich herum für ein paar Stunden vergessen und mein Kopf ist danach viel freier. Mir fällt es auch viel leichter zu lernen, weil meine Frustrationstoleranz sich durch das Training echt verbessert hat.",
            reviewer: "Paul",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "An BJJ liebe ich die Möglichkeit, trotz freundschaftlicher, kollegialer Atmosphäre eindeutig seine Grenzen aufgezeigt zu bekommen. Es gibt kein „hätte ich“, ‚„wenn ich“, „wäre ich“…Man ist konstant gezwungen besser zu werden, da es auf der Matte keine Ausflüchte gibt :D",
            reviewer: "Pascal",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Antigona",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Für mich ist BJJ einer der besten Wege Geistig vom Alltag abzuschalten, zu fokussieren und auch mich körperlich Auszugleichen, es ist Erholung für den Kopf und Seele bringt positive Energie und Motiviert ehrgeizig zu sein. Es hebt für mich einfach meine Lebensqualität.",
          },
        ]}
      />
      <Button
        to={"#contact"}
      >        Kostenlose Probestunde sichern
      </Button>
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        link="#contact"
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Fordere mehr Informationen zum Training mit unserem Kontaktformular an",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Wir melden uns bei dir und machen einen unverbindlichen und kostenlosen Termin für ein Probetraining mit dir aus.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Lerne uns und unsere Schule bei deinem Probetraining kennen. Wir freuen uns auf dich!",
          },
        ]}
      />
    </Layout >
  )
}
export const query = graphql`{
          hero1: file(relativePath: {eq: "BJJTrial/Gruppenfoto_mit_Chris_Paines.JPG"}) {
          childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
        hiptoss: file(relativePath: {eq: "BJJTrial/BJJ_München_K-Guard.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        heelhook: file(relativePath: {eq: "BJJTrial/Spass_am_BJJ_Training.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        triangle: file(relativePath: {eq: "BJJ/Spass_am_BJJ_Training.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        kids: file(relativePath: {eq: "BJJ/BJJ_Kids-knee-slide-pass.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust1: file(
        relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust5: file(
        relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust6: file(
        relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step1: file(
        relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step3: file(
        relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
}
        `
export default BJJ
